import $ from "jquery";

document.addEventListener("turbo:load", () => {

  $('input#filter_profiles').on('change keyup', function(){
    filter_entries();
  });

  if ($('input#filter_profiles').length > 0) filter_entries();

});

filter_entries = function() {
  entries = $("[data-entry]");
  entries.hide();
  entries = search_entries_by('fulltext', $('input#filter_profiles').val(), entries);
  entries.show();
  if ($("[data-entry]:visible").length == 0) {
    $(".no-entries").show();
  } else {
    $(".no-entries").hide();
  }
  if ($("[data-entry]:visible").length != $("[data-entry]").length) {
    $(".all-entries").show();
  } else {
    $(".all-entries").hide();
  }
}

search_entries_by = function(attr, val, entries) {
  if (val != "") {
    entries = entries.filter(function(){
      data_attr = $(this).data(attr);
      return data_attr.toLowerCase().includes(val.toLowerCase());
    });
  };
  return entries;
}
