import * as bootstrap from "bootstrap"
import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-free/js/all"
import Rails from '@rails/ujs';
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()
Rails.start();

import "./controllers/pages"
import "./controllers/filter_profiles"
import "./controllers/uppy"
import "./controllers/uppy_backend"
import "./controllers/state_changer"
import "./controllers/scroll_down"

// Stimulus Controllers
import "./stimulus_controllers/index.js"
