import { Controller } from "@hotwired/stimulus"

const openModalEvt = new CustomEvent("open-modal");

export default class extends Controller {

  click() {
    window.dispatchEvent(openModalEvt)
  }

}

