// Import the plugins
import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
// import XHRUpload from '@uppy/xhr-upload'
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
import ProgressBar from '@uppy/progress-bar'
import German from '@uppy/locales/lib/de_DE'

document.addEventListener("turbo:load", () => {
  const upload_form = document.getElementsByClassName("has-uppy")[0]
  
  if (upload_form != null) {
    const directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    const field_name = upload_form.dataset.uppy

    const uppy = new Uppy({
        debug: true,
        locale: German,
        autoProceed: true,
      })

    uppy.use(DragDrop, {
        target: '#uppy',
        height: 150,
      })

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: directUploadUrl
    })

    uppy.use(ProgressBar, {
        target: '#uppy_progress_bar',
        hideAfterFinish: false,
    })

    uppy.on('complete', (result) => {
      result.successful.forEach(file => {
        appendUploadedFileToForm(upload_form, file, field_name)
        appendUploadedFileToPreview(file)
      })
    })
  }

})

function appendUploadedFileToForm(element, file, field_name) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.signed_id)

  element.appendChild(hiddenField)
}

function appendUploadedFileToPreview(file) {
  let target = document.getElementById("uppy_assets")
  let preview_div = document.createElement('div')
  preview_div.innerHTML = "<i class='fas fa-check fa-fw'></i> " + file.name;
  let fragment = document.createDocumentFragment();
  fragment.appendChild(preview_div);
  target.appendChild(fragment);
}
