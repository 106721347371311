import $ from "jquery";

document.addEventListener("turbo:load", () => {

  $("#scroll_down").on("click", function(){

    $( "body, html" ).animate({ scrollTop: $( '#scroll_down' ).offset().top - 99 + 20 }, 300, "linear")
    
  })

})
