// Import the plugins
import Uppy from '@uppy/core'
// import DragDrop from '@uppy/drag-drop'
import DropTarget from '@uppy/drop-target'
import XHRUpload from '@uppy/xhr-upload'
// import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
import ProgressBar from '@uppy/progress-bar'
import German from '@uppy/locales/lib/de_DE'

document.addEventListener("turbo:load", () => {

  const attachmentAreas = document.querySelectorAll('.has-attachments-card');

  attachmentAreas.forEach((attachmentArea) => {
    
    let className = attachmentArea.getAttribute('data-class-name');
    let dataId = attachmentArea.getAttribute('data-id');
    let uploadUrl = `/administration/file_attacher/${className}/${dataId}.json`

    let uppy = new Uppy({
        debug: true,
        locale: German,
        autoProceed: true,
      })

    uppy.use(XHRUpload, {
      endpoint: uploadUrl,
    })

    uppy.use(DropTarget, {
      target: attachmentArea,
    })

    uppy.use(ProgressBar, {
        target: attachmentArea.querySelector('.admin-card-files-progress-bar'),
        hideAfterFinish: true,
    })

    uppy.on('complete', (result) => {
      result.successful.forEach(file => {
        appendUploadedFileToCard(attachmentArea, file)
      })
    })

  })

})

function appendUploadedFileToCard(attachmentArea, file) {
  let target = attachmentArea.querySelector('.admin-card-files');
  let preview_a = document.createElement('a');
  let preview_b = document.createElement('span');
  let fragment = document.createDocumentFragment();

  if (file.name.endsWith('.eml') == false) {
    preview_a.className = "me-3";
    preview_a.innerHTML = file.name;
    fragment.appendChild(preview_a);
  } else {
    preview_b.className = "me-3";
    preview_b.innerHTML = "E-Mail hinzugefügt";
    fragment.appendChild(preview_b);
  }

  fragment.appendChild(document.createTextNode (" "));
  target.appendChild(fragment);

}
