import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.initDropdown()
  }

  initDropdown(elem) {
    console.log(this.selectTargets)
    this.selectTargets.forEach( (selectTarget) => {
      new Choices(selectTarget, {
        itemSelectText: '',
        noResultsText: 'Keine Einträge gefunden',
        allowHTML: true
      })
    })
  }

}

