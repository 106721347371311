document.addEventListener("turbo:load", () => {

  const selectElements = document.querySelectorAll('.state-changer');

  selectElements.forEach((selectElement) => {

    selectElement.addEventListener('change', (event) => {
      // let style = event.target.options[event.target.selectedIndex].getAttribute('data-class');
      let state = event.target.options[event.target.selectedIndex].getAttribute('data-value');
      let obj =  event.target.getAttribute('data-class-name');
      let id =  event.target.getAttribute('data-id');
      let xhr = new XMLHttpRequest();
      
      // event.target.className = `state-changer ${style}`;
      xhr.open('POST', `/administration/state_changer/${obj}/${id}/${state}.json`);
      xhr.send();
    });

  });

});
