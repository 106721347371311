import CodeMirror from "codemirror"
import 'codemirror/mode/xml/xml.js'

document.addEventListener("turbo:load", () => {
  
  codemirror_input = document.getElementsByClassName("has-codemirror")[0]

  if (codemirror_input) {
    CodeMirror.fromTextArea(codemirror_input, {
      lineNumbers: true,
      mode: "text/html"
    })
  }

})
